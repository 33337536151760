/*
 Define the adapter so that it's reusable
 */
$.fn.select2.amd.define('select2/selectAllAdapter', [
    'select2/utils',
    'select2/dropdown',
    'select2/dropdown/attachBody'
], function (Utils, Dropdown, AttachBody) {

    function SelectAll() {
    }

    SelectAll.prototype.render = function (decorated) {
        var self = this,
            $rendered = decorated.call(this);

        if (!this.$element.prop("multiple")) {
            return $rendered;
        }

        var $selectAll = $('<a href="#" style="margin:0 3px;"><i class="fa fa-check-square-o"></i> Odaberite sve</a>'),
            $unselectAll = $('<a href="#" style="margin:0 3px;"><i class="fa fa-square-o"></i> Poništite sve</a>'),
            $btnContainer = $('<div style="margin:3px 0; font-size: 10px; text-align: right">').append($selectAll).append("|").append($unselectAll);

        $rendered.find('.select2-dropdown').prepend($btnContainer);
        $selectAll.on('click', function (e) {
            var $results = $rendered.find('.select2-results__option[aria-selected=false]');
            $results.each(function () {
                self.trigger('select', {
                    data: $(this).data('data')
                });
            });
            self.trigger('close');
        });
        $unselectAll.on('click', function (e) {
            var $results = $rendered.find('.select2-results__option[aria-selected=true]');
            $results.each(function () {
                self.trigger('unselect', {
                    data: $(this).data('data')
                });
            });
            self.trigger('close');
        });
        return $rendered;
    };

    return Utils.Decorate(
        Utils.Decorate(
            Dropdown,
            AttachBody
        ),
        SelectAll
    );
});